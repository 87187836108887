<section id="regestration" class="auth-height">
    <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="card overflow-hidden">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <!-- <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                <img src="../../../../assets/app-assets/img/gallery/register.png" alt="" class="img-fluid" width="350" height="230">
                            </div> -->
                            <div class="col-lg-12 col-md-12 px-4 py-3">
                                <h4 class="card-title mb-2">Create Account</h4>
                                <p>Fill the below form to create a new account.</p>
                                <form [formGroup]="Form" (ngSubmit)="onSubmit()">
                                <input type="text" class="form-control mb-2" formControlName="name" placeholder="Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                                <input type="email" class="form-control mb-2" formControlName="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                </div>
                                
                                <!-- <div class="checkbox auth-checkbox font-small-2 mb-3">
                                    <input type="checkbox" id="auth-login">
                                    <label for="auth-login"><span>I accept the terms & conditions</span></label>
                                </div> -->
                                <div class="d-flex justify-content-between flex-sm-row flex-column">
                                    <a routerLink="/u/auth/login" class="btn btn-secondary mb-2 mb-sm-0">Back To Login</a>
                                    <button [disabled]="loading" class="btn btn-info">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Register</button>
                                </div>
                            </form>
                            </div>
                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>