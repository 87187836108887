import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgetpass',
  templateUrl: './forgetpass.component.html',
  styleUrls: ['./forgetpass.component.scss']
})
export class ForgetpassComponent implements OnInit 
{
  Form: FormGroup;
  loading = false;
  submitted = false;

  constructor(private fb:FormBuilder, 
    private _authservice:AuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.Form = new FormGroup({
      email: new FormControl(),
   });

   this.Form = this.fb.group({
    email: ['', Validators.required],
});
  }
  get f() { return this.Form.controls; }

  onSubmit()
  {

    this.submitted = true;

    // stop here if form is invalid
    if (this.Form.invalid) {
        return;
    }
    this.loading = true;
    const email=this.Form.value.email;
    this._authservice.forgetPasswd(email).subscribe(
      res=>
      {
        alert(res.message)
        this.loading = false;
        this.router.navigate(['/u/auth/login']);
      },
      err=>
      {
        console.log(err)
        this.loading = false;
      }
    );

  }

}
