<section id="forgot-password" class="auth-height">
    <div class="row full-height-vh m-0 d-flex align-items-center justify-content-center">
        <div class="col-md-7 col-12">
            <div class="card overflow-hidden">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <!-- <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                                <img src="../../../../assets/app-assets/img/gallery/forgot.png" alt="" class="img-fluid" width="260" height="230">
                            </div> -->
                            <div class="col-lg-12 col-md-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Recover Password</h4>
                                <p class="card-text mb-3">Please enter your email address and we'll send you instructions on how to reset your password.</p>
                                <form [formGroup]="Form" (ngSubmit)="onSubmit()">
                                    <input type="email" class="form-control mb-2" formControlName="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                    </div>
                                <div class="d-flex flex-sm-row flex-column justify-content-between">
                                    <a routerLink="/u/auth/login" class="btn btn-secondary mb-2 mb-sm-0">Back To Login</a>
                                    <button [disabled]="loading" class="btn btn-info">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Recover</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>