<body class="vertical-layout vertical-menu 1-column auth-page navbar-sticky blank-page" data-menu="vertical-menu" data-col="1-column">
        <!-- ////////////////////////////////////////////////////////////////////////////-->
        <div class="wrapper">
            <div class="main-panel">
                <!-- BEGIN : Main Content-->
                <div class="main-content">
                    <div class="content-overlay"></div>
                    <div class="content-wrapper">
                        <!--Login Page Starts-->
                        <router-outlet></router-outlet>
                        <!--Login Page Ends-->
                    </div>
                </div>
                <!-- END : End Main Content-->
            </div>
        </div>


    </body>