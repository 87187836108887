import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from 'src/config';
import { AuthResponse } from './appInterface/auth-responce-interface';
import { UserResponse } from './appInterface/user-responce-interface';
import { Subject , BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from './appModels/user-model';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService 
{
  private user: BehaviorSubject<User>;
  constructor(private http:HttpClient, private router:Router)
   { 
    this.user = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('UserData')));
    //console.log(this.getcurrentUserValue());
   }
   signUp(name,email)
   {
      return this.http.post<AuthResponse>(`${config.BASE_URL}web/courier/unsigned/register`,{
        uname:name,
        email:email
      });
   }
   signIn(email,upass)
   {
    return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/unsigned/login`,
      {
        params: new HttpParams()
        .set('email',email)
        .set('upass',upass)
      }
      ).pipe(
      tap(res=>{
        if(res.status==true)
        {
          console.log(res.content['user']['utype']);
          if(res.content['user']['utype']=="C")
          {
           
            this.authUser(+res.content['user']['id'],res.content['user']['utype'],res.content['user']['email']
            ,res.content['user']['ukey'],res.content['user']['uname']);
          }
          
        }
        else
        {
          return;
        }
      })
      
    );
   }
  authUser(id,utype,email,ukey,name,bio?,occupation?,website?,location?)
   {
     let p_user;
     if(utype=="C")
     {
      p_user = new User(id,utype,email,ukey,name);
     }
      this.user.next(p_user);
      localStorage.setItem("UserData",JSON.stringify(p_user));
   }
   authLogin()
   {
     const userData=JSON.parse(localStorage.getItem('UserData'));
     let loggedinUser;
     if(!userData)
     {
        return;
     }
     if(userData.utype=="C")
     {
      loggedinUser= new User(userData.id,userData.utype,userData.email,userData.ukey,userData.name);
     }
     this.user.next(loggedinUser);
   }
   signOut()
   {
    this.user.next(null);
    //localStorage.clear();
    localStorage.removeItem('UserData');
    this.router.navigate(['/u/auth/login']);
    
   }
   public get currentUserValue(): User 
   {
    return this.user.value;
    } 
    forgetPasswd(email)
    {
      return this.http.get<AuthResponse>(`${config.BASE_URL}web/courier/unsigned/forget-password`,{
        params: new HttpParams()
        .set('email',email)
      });
    }

    //COURIER

  UpdateCProfile(cname,bio,occupation,website,location,ukey,id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/profile/edit`,{
        id:id,
        cname:cname,
        bio:bio,
        occupation:occupation,
        website:website,
        location:location
      },{headers:headers
      });
   }
   UpdateCPasswd(upass,ukey,id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });
      return this.http.put<UserResponse>(`${config.BASE_URL}web/merchant/signed/customer/profile/change-pass`,{
        id:id,
        upass:upass
      },{headers:headers
      });
   }

    AddDeliveryBoy(uname,email,image_url,ukey,user_id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.post<UserResponse>(`${config.BASE_URL}web/courier/signed/company/delivery-boy/add`,{
        user_id:user_id,
        uname:uname,
        email:email,
        image_url:image_url
      },{headers:headers
      });
   }
   ViewCDeliveryBoy(ukey,user_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/signed/company/delivery-boy/list`, 
      {
        params: new HttpParams()
        .set('user_id',user_id),
        headers:headers
      });
   }

   ViewCsDeliveryBoy(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/signed/company/delivery-boy/view`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }
  EditCDeliveryBoy(ukey,id,uname,email)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.put<UserResponse>(`${config.BASE_URL}web/courier/signed/company/delivery-boy/edit`,{
        id:id,
        uname:uname,
        email:email

      },{headers:headers
      });
   }
   DeleteCDeliveryBoy(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.delete<UserResponse>(`${config.BASE_URL}web/courier/signed/company/delivery-boy/delete`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
   }


   ViewAssignment(ukey,user_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/signed/company/assignment/list`, 
      {
        params: new HttpParams()
        .set('user_id',user_id),
        headers:headers
      });
   }
  

   ViewSAssignment(ukey,p_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/courier/signed/company/assignment/detail`, 
      {
        params: new HttpParams()
        .set('id',p_id),
        headers:headers
      });
    }
    ViewSOrder(ukey,p_id)
    {
      
      let headers= new HttpHeaders({
        'Content-Type':'application/json',
        'X-Api-Key':ukey
      });
  
        return this.http.get<UserResponse>(`${config.BASE_URL}web/merchant/signed/seller/order/courier-view`, 
        {
          params: new HttpParams()
          .set('id',p_id),
          headers:headers
        });
      
    }
    
    updateAssignment(ukey,id,user_id,delivery_date , status)
    {
      let headers= new HttpHeaders({
       'Content-Type':'application/json',
       'X-Api-Key':ukey
      });
 
       return this.http.put<UserResponse>(`${config.BASE_URL}web/courier/signed/company/assignment/assign-delivery-boy`,{
         id:id,
         user_id:user_id,
         delivery_date:delivery_date,
         status:status
 
       },{headers:headers
       });
    }

}
