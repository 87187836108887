import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate
 {
   constructor(private router:Router, private _authservice:AuthService)
   {

   }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
    {
      const currentUser = this._authservice.currentUserValue;
        if (currentUser) 
        {
            return true;
        }
        this.router.navigate(['/u/auth/login']);
        return false;
    }
  
}
